import { Component, OnInit } from '@angular/core';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import { faLightbulb as fasLightbulb } from '@fortawesome/free-solid-svg-icons';
import { faLightbulb as farLightbulb } from '@fortawesome/free-regular-svg-icons';
import { CustomColorSchemeService } from '@services/custom-color-scheme/custom-color-scheme.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    checked = false;
    localStorageTheme: string;
    // Icons
    faCopyright = faCopyright;
    farLightbulb = farLightbulb;
    fasLightbulb = fasLightbulb;

    date = new Date();
    currentYear: number;

    constructor(private customColorSchemeService: CustomColorSchemeService) {
        this.currentYear = this.date.getFullYear();
        this.localStorageTheme =
            this.customColorSchemeService.getCurrentTheme();
        if (this.localStorageTheme === 'dark') {
            this.checked = true;
            this.customColorSchemeService.setDarkTheme();
        } else {
            this.checked = false;
            this.customColorSchemeService.setLightTheme();
        }
    }

    ngOnInit(): void {}

    onToggleChange(isChecked: boolean) {
        if (isChecked) {
            this.customColorSchemeService.setDarkTheme();
        } else {
            this.customColorSchemeService.setLightTheme();
        }
    }
}
