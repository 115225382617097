import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    faGithub,
    faInstagram,
    faLinkedin,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faLightbulb as fasLightbulb } from '@fortawesome/free-solid-svg-icons';
import { faLightbulb as farLightbulb } from '@fortawesome/free-regular-svg-icons';
import { CustomColorSchemeService } from '@services/custom-color-scheme/custom-color-scheme.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
    currentUrl: string;
    localStorageTheme: string;
    checked = false;

    // Icons
    faGithub = faGithub;
    faInstagram = faInstagram;
    faLinkedin = faLinkedin;
    faYoutube = faYoutube;
    farLightbulb = farLightbulb;
    fasLightbulb = fasLightbulb;
    constructor(
        private router: Router,
        private customColorSchemeService: CustomColorSchemeService
    ) {
        this.localStorageTheme =
            this.customColorSchemeService.getCurrentTheme();
        if (this.localStorageTheme === 'dark') {
            this.checked = true;
            this.customColorSchemeService.setDarkTheme();
        } else {
            this.checked = false;
            this.customColorSchemeService.setLightTheme();
        }
    }

    ngOnInit(): void {
        this.currentUrl = this.router.url;
    }

    async goHome() {
        await this.router.navigate(['home']);
    }

    goTo(url: string): void {
        window.open(url, '_blank');
    }

    onToggleChange(isChecked: boolean) {
        if (isChecked) {
            this.customColorSchemeService.setDarkTheme();
        } else {
            this.customColorSchemeService.setLightTheme();
        }
    }
}
