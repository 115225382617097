import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
    public innerHeight: any;

    constructor(private router: Router) {}

    ngOnInit(): void {
        if (this.router.url === '/') {
            this.router.navigate(['home']).then();
        }
        this.onResize();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.innerHeight = window.innerHeight;
    }
}
