// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  background-color: var(--main-background-color);
}
.main .main-content .router {
  z-index: 1;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  min-height: 100vh;
  overflow-x: hidden;
  display: grid;
  place-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/main/main.component.scss"],"names":[],"mappings":"AAAA;EACI,8CAAA;AACJ;AAEQ;EACI,UAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,qBAAA;AAAZ","sourcesContent":[".main {\n    background-color: var(--main-background-color);\n\n    .main-content {\n        .router {\n            z-index: 1;\n            position: relative;\n            margin-left: auto;\n            margin-right: auto;\n            padding: 2rem;\n            min-height: 100vh;\n            overflow-x: hidden;\n            display: grid;\n            place-content: center;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
