import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { appThemeStateReducer } from '@services/ngrx/appTheme/appTheme.reducer';

// * Component imports
import { AppComponent } from './app.component';
import { MainComponent } from './layout/main/main.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FooterComponent } from './components/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StoreModule } from '@ngrx/store';
import { StorageService } from '@services/local-storage/local-storage.service';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';

export function playerFactory() {
    return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        NavigationComponent,
        FooterComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FontAwesomeModule,
        StoreModule.forRoot({
            appThemeState: appThemeStateReducer,
        }),
        StoreModule.forRoot({}, {}),
        LottieModule.forRoot({ player: playerFactory }),
    ],
    providers: [StorageService],
    bootstrap: [AppComponent],
})
export class AppModule {}
